<div class="header">
  <a 
    [href]="logoUrl"
    target="_blank"
    class="header_title">
    <img class="header_logo" src="assets/img/readcube-logo.svg" alt="Papers logo">
    papers
  </a>
  <div class="header_button-box" *ngIf="authService.userHasCollection$ | async">
    <button class="button button--blue" (click)="openPapers()">Open in Papers</button>
  </div>
</div>