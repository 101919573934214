export const environment = {
  name: 'staging',
  production: false,
  baseUrls: {
    papers: '//papersapp.com',
    readcube: '//staging.readcube.com',
    support: '//support.readcube.com',
    sync: 'https://sync-staging.readcube.com',
    services: 'https://services-staging.readcube.com',
    lambda: 'https://api-gateway.readcube.com',
    app: 'https://lists-staging.papersapp.com',
    webapp:'https://app-staging.readcube.com'
  },
  injectableButtonUrl: 'https://content-staging.readcube.com/injectable-button-assets/readcube-papers-button.min.js',
  clientName: 'readcube-lists-staging',
  maxRequestRetryAttempts: 5,
  trackJsApp: 'public-lists',
  trackJsToken: '4587af51bbb542afbbc9d93bf9567159',
  trackJsEnabled: true
};