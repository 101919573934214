<ng-container *ngIf="listService.name$ | async">
  <div 
    class="to-top-button"
    (click)="scrollToTop()">
    <fa-icon [icon]="['fal', 'chevron-up']"></fa-icon>
  </div>

  <div class="search-box_title">
    {{ listService.name$ | async }}
  </div>
  <div class="search-box_bar">
    <input
      #searchInput 
      type="text" 
      [placeholder]="'Search'"
      (keydown.enter)="onSearch()">
    <div 
      class="search-box_icon-search"
      (click)="onSearch()">
      <fa-icon [icon]="['far', 'search']"></fa-icon>
    </div>
    <div 
      class="search-box_icon-close"
      (click)="onClearSearch()">
      <fa-icon [icon]="['fal', 'times-circle']"></fa-icon>
    </div>
  </div>

  <div class="search-box_navigation">
    <div
      #sortBox
      class="search-box_sort relative">

      Sort:&nbsp; 
      <strong
        (click)="isDropdownVisible = !isDropdownVisible">
        {{ sortOptions[queryParams.sort] }}
      </strong>

      <fa-icon 
        class="search-box_sort-icon"
        [icon]="['fas', 'caret-down']"
        (click)="isDropdownVisible = !isDropdownVisible"></fa-icon>
      <div
        class="dropdown dropdown--left"
        [ngClass]="{ 'hidden': !isDropdownVisible}">
        <div 
          *ngFor="let opt of sortOptions | keyvalue"
          class="dropdown_item"
          [ngClass]="{'dropdown_item--active': queryParams.sort == opt.key }"
          (click)="onSortChange(opt.key)">
          {{opt.value}}
        </div>
        <div class="dropdown_divider"></div>
        <div 
          class="dropdown_item"
          *ngFor="let opt of orderOptions | keyvalue"
          [ngClass]="{'dropdown_item--active': queryParams.order == opt.key }"
          (click)="onOrderChange(opt.key)">
          {{opt.value}}
        </div>
      </div>
    </div>

    <div
      #exportBox
      *ngIf="!(loadingExport$ | async), else loadingExportTemplate" 
      class="search-box_export relative pointer"
      (click)="isExportVisible = !isExportVisible">
      <div *ngIf="exportFailed$ | async" class="field-error">
        Export failed. Please try again. &nbsp;
      </div>
      Export List
      <div 
        class="search-box_sort-icon">
        <fa-icon [icon]="['fal', 'arrow-to-bottom']"></fa-icon>
      </div>

      <div
        class="dropdown dropdown--right"
        [ngClass]="{ 'hidden': !isExportVisible}">
        <div 
          class="dropdown_item"
          (click)="onRisExport()">
          Export as RefMan (.ris)
        </div>
        <div 
          class="dropdown_item"
          (click)="onBibExport()">
          Export as BibTeX (.bib)
        </div>
      </div>
    </div>

    <ng-template #loadingExportTemplate>
      <rcl-loader class="search-box_loader"></rcl-loader>
    </ng-template>

  </div>
</ng-container>

  <div 
    *ngIf="items"
    infiniteScroll
    [scrollOffset]="300"
    [scrollContainer]="scrollContainer"
    [onScrollCallback]="onScrollList.bind(this)">
    <div 
      *ngIf="!items.length"
      class="margin-top-sm">
      Unfortunately, we found no results for your query. Please try again.
    </div>
    <div 
      *ngFor="let item of items; trackBy: trackByFn"
      class="list-item">
      <div 
        class="list-item_title"
        [ngClass]="{ 'cursor-default': !item.article?.abstract }"
        (click)="toggleShowAbstract(item.article)">
        {{ item.article.title || 'Untitled' }}
      </div>
      <div class="list-item_subtitle-box">
        <ng-container *ngIf="!!item.article.displayedAuthors.length">
          <div 
            *ngFor="let author of (item.article.isFullAuthorsShown ? item.article.authors : item.article.displayedAuthors)"
            class="list-item_author"
            (click)="onAuthorSearch(author)">
            {{ author }}
          </div>
        </ng-container>
        <div 
          *ngIf="!!item.article.nonDisplayedAuthorsCount"
          (click)="toggleShowAuthors(item.article)"
          class="badge">
          {{ item.article.isFullAuthorsShown ? 'less' : ('&#43; ' + item.article.nonDisplayedAuthorsCount + (item.article.nonDisplayedAuthorsCount === 1 ? ' author' : ' authors')) }}
        </div>
        <div
          class="pointer"
          *ngIf="item.article.journal" 
          (click)="onJournalSearch(item.article.journal)">
          {{ item.article.journal }}
        </div>
        <div *ngIf="item.article.last_modified, else yearTemplate">
          {{ item.article.last_modified | date: 'MMMM, yyyy' }}
        </div>
        <ng-template #yearTemplate> 
          <div>{{ item.article.year }}</div>
        </ng-template>
      </div>
      <div *ngIf="item.article.abstract" class="list-item_content">
      {{ (!item.article.isAbstractTooLong || item.article.isFullAbstractShown) ? item.article.abstract : (item.article.abstract | truncate: abstractTruncateCount) }} 
        <span 
          *ngIf="item.article.isAbstractTooLong"
          class="badge"
          (click)="toggleShowAbstract(item.article)">
          {{ item.article.isFullAbstractShown ? 'less' : 'more' }} 
        </span>
      </div>
      <div class="list-item_button-box" *ngIf="item.ext_ids.doi">
        <div 
          class="__readcube-access-button" 
          [attr.data-doi]="item.ext_ids.doi">
        </div>
        <div 
          *ngIf="!isSafari" 
          class="__readcube-library-button" 
          [attr.data-doi]="item.ext_ids.doi">
        </div>
        <div 
          class="__readcube-share-button" 
          [attr.data-doi]="item.ext_ids.doi">
        </div>
        <div 
          class="badge" 
          *ngIf="item.article?.url">
          <a href="{{ item.article.url }}" target="_blank">doi: {{ item.ext_ids.doi | truncate: doiTruncateCount }}</a>
        </div>
      </div>
    </div>

    <rcl-loader *ngIf="(loadingMore$ | async)" class="loader_more-container"></rcl-loader>
  </div>

<div *ngIf="(loading$ | async)" class="loader_overlay">
  <rcl-loader class="center-horizontal-vertical"></rcl-loader>
</div>