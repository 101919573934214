// native
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'rcl-field-error',
  templateUrl: './field-error.component.html'
})
export class FieldErrorComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() fieldName?: string;

  constructor() { }

  ngOnInit(): void {
  }
}
