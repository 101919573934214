// native
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, empty, Subject, BehaviorSubject } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

// service
import { ListService } from '../../../core/services/list.service';
import { ExportService } from '../../../core/services/export.service';
import { ScriptLoadingService } from '../../../core/services/script-loading.service';

// model
import { IArticle, IItem, IItemQueryParams } from '../../../models/item.model';

// utilities
import { isSafari, scrollToTop } from '../../../utilities/utilities';

// constants
import { BREAKPOINT_MOBILE, MAX_ABSTRACT_LENGTH, MAX_ABSTRACT_LENGTH_MOBILE, MAX_DOI_LENGTH } from '../../../constants/constants';

@Component({
  selector: 'rcl-list',
  templateUrl: './list.component.html',
  host: {
    'class': 'content',
    '(document:click)': 'onOutsideDropdownClick($event)'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnInit, AfterViewInit {

  @ViewChild('searchInput') searchInputElement: ElementRef;
  @ViewChild('sortBox') sortBoxElement: ElementRef;
  @ViewChild('exportBox') exportBoxElement: ElementRef;

  items: IItem[];
  abstractTruncateCount = (window.screen.width > BREAKPOINT_MOBILE) ? MAX_ABSTRACT_LENGTH : MAX_ABSTRACT_LENGTH_MOBILE;
  doiTruncateCount = MAX_DOI_LENGTH;

  sortOptions = {
    'title': 'Title',
    'first_author': 'Author',
    'journal': 'Journal',
    'year': 'Year',
    'created': 'Date Added'
  };

  orderOptions = {
    'asc': 'Ascending',
    'desc': 'Descending'
  };

  queryParams: IItemQueryParams = {
    sort: 'title',
    order: 'asc'
  };

  isDropdownVisible: boolean = false;
  isExportVisible: boolean = false;

  id: string;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingMore$: Subject<boolean> = new Subject<boolean>();

  loadingExport$: Subject<boolean> = new Subject<boolean>();
  exportFailed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  scrollContainer: HTMLElement;
  scrollToTop: Function = scrollToTop;
  isSafari: boolean = isSafari();

  constructor(
    public listService: ListService,
    private scriptLoadingService: ScriptLoadingService,
    @Inject(DOCUMENT)
    private document: HTMLDocument,
    private route: ActivatedRoute,
    private exportService: ExportService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getItems();
    });

    this.scrollContainer = <HTMLElement>this.document.querySelector('.content-wrapper');
  }

  ngAfterViewInit(): void {
    !this.scriptLoadingService.isInjectableButtonsScriptLoaded && this.scriptLoadingService.loadInjectableButtonScript();
  }

  toggleShowAbstract(article: Partial<IArticle>) {
    article.isFullAbstractShown = !article.isFullAbstractShown;
  }

  toggleShowAuthors(article: Partial<IArticle>) {
    article.isFullAuthorsShown = !article.isFullAuthorsShown;
  }

  onSearch() {
    this.queryParams.query = (<HTMLInputElement>this.searchInputElement.nativeElement).value;
    this.queryParams.isScroll = false;

    this.getItems();
  }

  onAuthorSearch(term: string) {
    this.queryParams.query = `author:"${term}"`;
    this.queryParams.isScroll = false;
    this.updateCurrentSearchTerm();

    this.getItems();
  }

  onJournalSearch(term: string) {
    this.queryParams.query = `journal:"${term}"`;
    this.queryParams.isScroll = false;
    this.updateCurrentSearchTerm();

    this.getItems();
  }

  updateCurrentSearchTerm() {
    (<HTMLInputElement>this.searchInputElement.nativeElement).value = this.queryParams.query;
  }

  onClearSearch() {
    this.queryParams.query = null;
    this.queryParams.isScroll = false;
    this.updateCurrentSearchTerm();

    this.getItems();
  }

  getItems(): Observable<IItem[]> {
    !this.queryParams.isScroll && this.loading$.next(true);
    !this.queryParams.isScroll && (this.items = null);

    this.queryParams.isScroll && this.loadingMore$.next(true);

    const items$ = this.listService.getItems(this.id, this.queryParams)
      .pipe(
        publishReplay(1),
        refCount()
      );

    items$.subscribe((items: IItem[]) => {
      !this.queryParams.isScroll && scrollToTop();
      !this.queryParams.isScroll && this.loading$.next(false);

      this.queryParams.isScroll && this.loadingMore$.next(false);

      this.items = items;
    }, err => {
      !this.queryParams.isScroll && this.loading$.next(false);
      this.queryParams.isScroll && this.loadingMore$.next(false);
      throw(err);
    });

    return items$;
  }

  onSortChange(sort: string) {
    this.isDropdownVisible = false;
    this.queryParams.sort = sort;
    this.queryParams.isScroll = false;

    this.getItems();
  }

  onOrderChange(order: string) {
    this.isDropdownVisible = false;
    this.queryParams.order = <'asc' | 'desc'>order;
    this.queryParams.isScroll = false;

    this.getItems();
  }

  onScrollList() {
    if (this.listService.isNextScrollSearchDisabled)
      return empty();

    this.queryParams.isScroll = true;
    return this.getItems();
  }

  onRisExport() {
    this.loadingExport$.next(true);

    this.exportService.populateItems(this.id, null).then(items => {
      this.exportService.exportRIS({ items });
      this.loadingExport$.next(false);
    }).catch(err => {
      this.loadingExport$.next(false);
      this.showExportFailedNotification();
    });
  }

  onBibExport() {
    this.loadingExport$.next(true);

    this.exportService.populateItems(this.id, null).then(items => {
      this.exportService.exportBIB({ items });
      this.loadingExport$.next(false);
    }).catch(err => {
      this.loadingExport$.next(false);
      this.showExportFailedNotification();
    });
  }

  trackByFn(index: number, item: IItem) {
    return item.id;
  }

  onOutsideDropdownClick(event) {
    if (!this.sortBoxElement?.nativeElement.contains(event.target))
      this.isDropdownVisible = false;

    if (!this.exportBoxElement?.nativeElement.contains(event.target))
      this.isExportVisible = false;
  }

  private showExportFailedNotification() {
    this.exportFailed$.next(true);
    setTimeout(() => {
      this.exportFailed$.next(false)
    }, 4000);
  }
}
