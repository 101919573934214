// native
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retryWhen, switchMap, delayWhen, tap, take } from 'rxjs/operators';

// environment
import { environment } from '../environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Accept': 'application/json',
      },
      setParams: {
        'client': environment.clientName,
      },
      withCredentials: true
    });

    return next.handle(request).pipe(catchError((response: HttpErrorResponse) => {
      switch (response.status) {
        case 401: // Unauthorized
        case 403: // Forbidden
        case 404: // Not Found
          return throwError(response);
        case 422: // Unprocessable Entity
          return throwError(response);
        case 429: // Too Many Requests
          return this.getRateLimitResetTimer(response).pipe(
            tap(() => console.log('Retrying request...')),
            switchMap(() => next.handle(request.clone())),
            retryWhen((errors) => errors.pipe(
              delayWhen(response => this.getRateLimitResetTimer(response)),
              take(environment.maxRequestRetryAttempts)
            ))
          );
        case 503:
          return this.getRetryAfterTimer(response).pipe(
            tap(() => console.log('Retrying request...')),
            switchMap(() => next.handle(request.clone())),
            retryWhen((errors) => errors.pipe(
              delayWhen(response => this.getRetryAfterTimer(response)),
              take(environment.maxRequestRetryAttempts)
            ))
          );
        default:
          return throwError(response);
      }
    }));
  }

  protected getRateLimitResetTimer(response: HttpErrorResponse): Observable<number> {
    const resetTime = parseInt(response.headers.get('RateLimit-Reset'), 10);
    const nowTime = Date.now() / 1000;
    let dueTime = resetTime - nowTime;
    dueTime = dueTime < 2 ? 2 : Math.ceil(dueTime);
    return timer(dueTime * 1000);
  }

  protected getRetryAfterTimer(response: HttpErrorResponse): Observable<number> {
    let retryTime = parseInt(response.headers.get('Retry-After'), 10);
    retryTime = retryTime < 2 ? 2 : retryTime;
    return timer(retryTime * 1000);
  }
}