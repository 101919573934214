import {
  Directive,
  AfterViewInit,
  ElementRef,
  Input,
  OnDestroy
} from '@angular/core';

import {
  Observable,
  Subscription,
  fromEvent
} from 'rxjs';

import {
  pairwise,
  map,
  filter,
  startWith,
  debounceTime,
  exhaustMap
} from 'rxjs/operators';

interface ScrollPosition {
  scrollHeight: number;
  scrollTop: number;
  clientHeight: number;
}

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
  scrollHeight: 0,
  scrollTop: 0,
  clientHeight: 0
};

@Directive({
  selector: '[infiniteScroll]'
})
export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {

  @Input() scrollContainer: HTMLElement;

  @Input() isImmediateCallback: boolean;

  @Input() scrollOffset: number;

  @Input() onScrollCallback;

  private scrollEvent$: Observable<any>;
  private userScrolledDown$: Observable<any>;
  private requestOnScroll$: Observable<any>;

  private scrollSubscription: Subscription;

  constructor(
    private element: ElementRef,
  ) { }

  ngAfterViewInit() {
    this.registerScrollEvent();
    this.streamScrollEvents();
    this.requestCallbackOnScroll();
  }

  ngOnDestroy() {
    this.scrollSubscription?.unsubscribe();
  }

  private registerScrollEvent() {
    this.scrollEvent$ = fromEvent(this.scrollContainer || this.element.nativeElement, 'scroll');
  }

  private streamScrollEvents() {
    this.userScrolledDown$ = this.scrollEvent$
      .pipe(
        debounceTime(20),
        map((event: any): ScrollPosition => ({
          scrollHeight: event.target.scrollHeight,
          scrollTop: event.target.scrollTop,
          clientHeight: event.target.clientHeight
        })),
        pairwise(),
        filter((positions: ScrollPosition[]) => this.isUserScrollingDown(positions) && this.isOffsetReached(positions[1])));
  }

  private requestCallbackOnScroll() {
    this.requestOnScroll$ = this.userScrolledDown$;

    if (this.isImmediateCallback) {
      this.requestOnScroll$ = this.requestOnScroll$.pipe(
        startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION])
      );
    }

    this.scrollSubscription = this.requestOnScroll$.pipe(
      exhaustMap(() => { return this.onScrollCallback(); })
    ).subscribe((response) => { });
  }

  private isUserScrollingDown = (positions: ScrollPosition[]) => {
    return positions[0].scrollTop < positions[1].scrollTop;
  };

  private isOffsetReached = (position: ScrollPosition) => {
    return ((position.scrollHeight - position.scrollTop - position.clientHeight) < this.scrollOffset);
  };
}
