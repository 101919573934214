export function scrollToTop() {
  window.document.querySelector('.content-wrapper')!.scrollTop = 0;
}

export function scrollToElement(el: Element): void {
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

export function isSafari() {
  return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
}