export class ScrollSession {
  protected _id: string;
  protected _ttl: number;
  protected _created: Date;

  constructor(id: string, ttl: number = 600) {
    this._id = id;
    this._ttl = ttl;
    this._created = new Date();
  }

  get id(): string | null {
    if (this.expired()) {
      return null;
    }
    return this._id;
  }

  expired(): boolean {
    const a = this._created.getTime() / 1000,
          b = Date.now() / 1000;
    return Math.abs(a - b) >= this._ttl;
  }
}