// native
import { Component } from '@angular/core';

@Component({
  selector: 'rcl-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {

  constructor() { }
}
