// native
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

// service
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

// models
import { IItem, IItemQueryParams, IItemsResult } from '../../models/item.model';
import { ScrollSession } from '../../models/scroll-session.model';

// environment
import { environment } from '../../../environments/environment';

// constants
import {
  MAX_ABSTRACT_LENGTH,
  MAX_AUTHORS_LENGTH,
  MAX_SEARCH_TERM,
  DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_ORDER,
  DEFAULT_QUERY_SIZE,
  MAX_ABSTRACT_LENGTH_MOBILE,
  MAX_AUTHORS_LENGTH_MOBILE,
  BREAKPOINT_MOBILE
} from '../../constants/constants';

// utilities
import { scrollToTop } from '../../utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  totalArticles: number;
  items: IItem[];
  isNextScrollSearchDisabled: boolean = false;

  name$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  entity: {
    type: string;
    collectionId: string;
    listId: string;
    query: string;
    sortField?: string;
    sortOrder?: string;
  };

  query: string;

  maxAuthorsLength: number;
  maxAbstractLength: number;

  private scrollSession: ScrollSession;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router
  ) {
    this.maxAuthorsLength = (window.screen.width > BREAKPOINT_MOBILE) ? MAX_AUTHORS_LENGTH : MAX_AUTHORS_LENGTH_MOBILE;
    this.maxAbstractLength = (window.screen.width > BREAKPOINT_MOBILE) ? MAX_ABSTRACT_LENGTH : MAX_ABSTRACT_LENGTH_MOBILE;
  }

  getItems(id: string, params: IItemQueryParams): Observable<IItem[]> {
    if (params.query && params.query.length > MAX_SEARCH_TERM)
      return of([]);

    const endpointUrl = `${environment.baseUrls.sync}/public/items?id=${id}`;

    const queryParams = this.getHttpParams(params, this.scrollSession);

    return this.apiService.get(endpointUrl, queryParams).pipe(
      catchError(err => {
        if ((err.status == 404) && !params.isScroll)
          this.router.navigateByUrl('/page-not-found');
        if (err.error?.error === 'scroll_id_expired') {
          this.scrollSession = null;
          const url = this.router.url;
          this.router.navigateByUrl('.', { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
        }

        return throwError(err);
      }),
      tap(response => {
        this.scrollSession = new ScrollSession(response.scroll_id);
      }),
      tap((response: IItemsResult) => this.name$.next(response.name)),
      tap((response: IItemsResult) => this.setEntityInfo(response)),
      map((response: IItemsResult) => this.parseSearchResponse(response)),
      tap((results: IItem[]) => this.disableNextScrollSearchIfNeeded(results, params.isScroll)),
      map((results: IItem[]) => this.handleScrollingAccumulation(results, params.isScroll, this.scrollSession))
    );
  }

  private getHttpParams(params: IItemQueryParams, scrollSession: ScrollSession): HttpParams {
    let httpParams = new HttpParams();
    if (params.query)
      httpParams = httpParams.append('query', params.query);

    if (params.sort)
      httpParams = httpParams.append('sort[]', [params.sort, params.order || DEFAULT_SORT_ORDER].join(','));

    // extra sort filter added in case first sort filter results in multiple exact records with given sort criteria
    httpParams = httpParams.append('sort[]', [DEFAULT_SORT_COLUMN, DEFAULT_SORT_ORDER].join(','));

    if (params.isScroll && scrollSession && !scrollSession.expired())
      httpParams = httpParams.set('scroll_id', scrollSession.id);

    httpParams = httpParams.set('size', DEFAULT_QUERY_SIZE.toString());

    return httpParams;
  }

  private disableNextScrollSearchIfNeeded(results: IItem[], isScroll: boolean) {
    this.isNextScrollSearchDisabled = (isScroll && results.length === 0);
  }

  private parseSearchResponse(response: any): IItem[] {
    response.items.forEach(item => {
      if (!item?.article)
        return;

      if (item.article.year && item.article.month)
        item.article.last_modified = `${item.article.year}-${item.article.month}-01`;

      if (item.article.authors && item.article.authors.length) {
        item.article.displayedAuthors = this.getDisplayedAuthors(item.article.authors, item.article.journal);
        item.article.nonDisplayedAuthorsCount = item.article.authors.length - item.article.displayedAuthors.length;
      } else {
        item.article.displayedAuthors = [];
        item.article.nonDisplayedAuthorsCount = 0;
      }

      item.article.isFullAuthorsShown = false;
      item.article.isFullAbstractShown = false;
      item.article.isAbstractTooLong = item.article.abstract
        && item.article.abstract.length && (item.article.abstract.length > this.maxAbstractLength);
    });

    return response.items;
  }

  private getDisplayedAuthors(authors: string[], journal: string): string[] {
    let totalAuthorChars = 0;
    let maxAuthorChars = this.maxAuthorsLength - (journal?.length || 0);

    let displayedAuthorsCount = 0;

    for (const author of authors) {
      totalAuthorChars = totalAuthorChars + author.length;
      if ((totalAuthorChars > maxAuthorChars) && (displayedAuthorsCount > 0)) break;
      displayedAuthorsCount++;
    }

    return authors.slice(0, displayedAuthorsCount);
  }

  private handleScrollingAccumulation(results: IItem[], isScroll: boolean, scrollSession: ScrollSession): IItem[] {
    if (!isScroll || !scrollSession || scrollSession.expired()) {
      scrollToTop();

      this.items = results;
      return results;
    }
    this.items = this.items.concat(results);
    return this.items;
  }

  private setEntityInfo(res: IItemsResult): void {
    if (!res?.object)
      return;

    this.authService.userHasCollection$.next(true);

    this.entity = {
      type: res.type,
      collectionId: res.object?.collection_id,
      listId: res.object?.id,
      query: res.object?.query
    };

    if (Array.isArray(res.object.sort) && res.object.sort.length > 0) {
      let field = res.object.sort[0].split(',')[0];
      let order = res.object.sort[0].split(',')[1];

      this.entity.sortField = field;
      this.entity.sortOrder = order;
    }
  }
}