// native
import { Component, OnInit } from '@angular/core';

// service
import { ApiService } from './core/services/api.service';
import { ScriptLoadingService } from './core/services/script-loading.service';
import { TrackingService } from './core/services/tracking.service';

// environment
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private trackingService: TrackingService,
    private scriptLoadingService: ScriptLoadingService
  ) { }

  ngOnInit() {
    this.apiService.get(`${environment.baseUrls.services}/users/info_website`).subscribe(res => {
      this.trackingService.userId = res.id;
      !this.scriptLoadingService.isAnalyticsScriptLoaded && this.scriptLoadingService.loadAnalyticsScript();
    }, err => {
      !this.scriptLoadingService.isAnalyticsScriptLoaded && this.scriptLoadingService.loadAnalyticsScript();
    });
  }

}
