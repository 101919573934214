<div class="rcl-container">
  <div class="navbar">
    <rcl-header></rcl-header>
  </div>

  <div class="main">
    <rcl-sidebar class="sidebar"></rcl-sidebar>

    <div class="content-wrapper">
      <router-outlet></router-outlet>
      <rcl-sidebar class="sidebar"></rcl-sidebar>
    </div>
  </div>

  <rcl-footer class="footer"></rcl-footer>
</div>