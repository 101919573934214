// native
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  get(path: string, params?: HttpParams, headers?: HttpHeaders): Observable<any> {
    return this.httpClient.get(path, { params, headers })
      .pipe(
        catchError(error => throwError(error))
      );
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.httpClient.post(path, body)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.httpClient.patch(path, body)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.httpClient.put(path, body)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  delete(path: string): Observable<any> {
    return this.httpClient.delete(path)
      .pipe(
        catchError(error => throwError(error))
      );
  }
}
