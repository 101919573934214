// native
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

// service
import { ApiService } from './api.service';
import { asBib, asRis } from '@readcube/rcp-citation-export';
import { saveAs } from 'file-saver';

// models
import { IItem } from '../../models/item.model';

// environment
import { environment } from '../../../environments/environment';

// constants
import { DEFAULT_SORT_ORDER, DEFAULT_SORT_COLUMN } from '../../constants/constants';

export interface IExportParams {
  collectionId?: string;
  items: IItem[];
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  scroll_id: string;

  constructor(
    private apiService: ApiService
  ) { }

  populateItems(id: string, scrollId: string): Promise<IItem[]> {
    return new Promise(async (resolve, reject) => {
      this.getItems(id, scrollId, resolve, reject);
    });
  }

  private getItems(id: string, scrollId: string, resolve: Function, reject: Function, acc: IItem[] = []): void {
    let endpointUrl = `${environment.baseUrls.sync}/public/items?id=${id}&sort%5B%5D=${DEFAULT_SORT_COLUMN},${DEFAULT_SORT_ORDER}`;

    if (scrollId)
      endpointUrl = endpointUrl + `&scroll_id=${scrollId}`;

    this.apiService.get(endpointUrl).pipe(
      tap((response: any) => this.saveScrollId(response)),
      map((response: any) => response.items)
    ).subscribe(items => {
      if (items.length)
        this.getItems(id, this.scroll_id, resolve, reject, acc.concat(items));
      else
        resolve(acc);
    }, err => reject(err));
  }

  private saveScrollId(response: any) {
    this.scroll_id = response.scroll_id;
  }

  exportRIS(params: IExportParams): Promise<void> {
    const newLine = '\r\n';
    const promises = params.items.map(item => {
      const s = asRis({ item, newLine });
      return s.concat(newLine);
    });
    return Promise.all(promises).then(entries => {
      const fileName = this.getExportFileName(params.items, 'ris');
      const blob = new Blob(entries, { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
    });
  }

  exportBIB(params: IExportParams): Promise<void> {
    const newLine = '\r\n';
    const promises = params.items.map(item => {
      const s = asBib({ item, newLine });
      return s.concat(newLine);
    });
    return Promise.all(promises).then(entries => {
      const fileName = this.getExportFileName(params.items, 'bib');
      const blob = new Blob(entries, { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
    });
  }

  private getExportFileName(items: any[], ext: string): string {
    if (items.length != 1) {
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      return `export_${y}-${m}-${d}.${ext}`;
    }
    const item = items[0];
    const authors = item.article.authors ?? [];
    const name: string[] = [];
    if (authors.length > 0) {
      name.push(authors[0].split(/[\s]+/).pop());
    } else {
      name.push('anonymous');
    }
    if (item.article.year) {
      name.push(item.article.year.toString());
    }
    return name.join('')
      .toLowerCase()
      .replace(/\W/g, '')
      .trim()
      .concat('.', ext);
  }
}