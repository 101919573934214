// native
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

// service
import { ScriptLoadingService } from '../../core/services/script-loading.service';

// utilites
import { animate404 } from '../../utilities/animation';

@Component({
  selector: 'rcl-not-found',
  templateUrl: './notfound.component.html',
  host: {
    'class': 'content'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit {

  linkUrl: string;

  constructor(
    private scriptLoadingService: ScriptLoadingService
  ) {
    const subject = 'List is no longer being shared';
    this.linkUrl = `mailto:support@readcube.com?subject=${encodeURIComponent(subject)}`;
  }

  ngOnInit() {
    if (this.scriptLoadingService.areAnimationScriptsLoaded)
      return animate404();

    this.scriptLoadingService.loadAnimationScripts().then(() => {
      this.scriptLoadingService.areAnimationScriptsLoaded = true;
      animate404();
    });
  }
}
