export const MAX_ABSTRACT_LENGTH = 330;
export const MAX_ABSTRACT_LENGTH_MOBILE = 200;
export const MAX_AUTHORS_LENGTH = 90;
export const MAX_AUTHORS_LENGTH_MOBILE = 70;
export const MAX_DOI_LENGTH = 40;

export const BREAKPOINT_MOBILE = 567;

export const TYPE = {
  COLLECTION: 'collection',
  SMARTLIST: 'smartlist',
  LIST: 'list'
};

export const MAX_SEARCH_TERM = 200;
export const DEFAULT_SORT_COLUMN = 'title';
export const DEFAULT_SORT_ORDER = 'asc';
export const DEFAULT_QUERY_SIZE = 50;

export const EASEL_SCRIPT_SRC = 'https://code.createjs.com/easeljs-0.8.1.min.js';
export const TWEEN_SCRIPT_SRC = 'https://code.createjs.com/tweenjs-0.6.1.min.js';
export const MOVIECLIP_SCRIPT_SRC = 'https://code.createjs.com/movieclip-0.8.1.min.js';