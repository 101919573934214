// native
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// service
import { TrackingService } from './tracking.service';

// environment
import { environment } from '../../../environments/environment';

// constants
import { EASEL_SCRIPT_SRC, MOVIECLIP_SCRIPT_SRC, TWEEN_SCRIPT_SRC } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoadingService {

  constructor(
    @Inject(DOCUMENT) private document: any,
    private trackingService: TrackingService
  ) { }

  isInjectableButtonsScriptLoaded: boolean;
  areAnimationScriptsLoaded: boolean;
  isAnalyticsScriptLoaded: boolean;

  loadInjectableButtonScript() {
    const injectableButtonScriptElement = this.document.createElement('script');
    injectableButtonScriptElement.src = environment.injectableButtonUrl;

    injectableButtonScriptElement.onload = () => {
      this.isInjectableButtonsScriptLoaded = true;
    };

    this.document.head.appendChild(injectableButtonScriptElement);
  }

  loadAnimationScripts(): Promise<[void, void]> {
    return this.getScript(EASEL_SCRIPT_SRC).then(() => {
      return Promise.all([
        this.getScript(TWEEN_SCRIPT_SRC),
        this.getScript(MOVIECLIP_SCRIPT_SRC)
      ]);
    });
  }

  private getScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const scriptElement = this.document.createElement('script');
      scriptElement.src = src;

      scriptElement.onload = () => {
        resolve();
      };

      this.document.head.appendChild(scriptElement);
    });

  }

  loadAnalyticsScript() {
    window['dataLayer'] = window['dataLayer'] || [];
    window['gtag'] = function () { window['dataLayer'].push(arguments); };

    window['gtag']('js', new Date());

    const googleTagManagerScript = this.document.createElement('script');
    googleTagManagerScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-134722866-4';

    googleTagManagerScript.onload = () => {
      this.isAnalyticsScriptLoaded = true;
      this.trackingService.sendGoogleAnalyticsPageView();
    };

    this.document.head.appendChild(googleTagManagerScript);
  }
}