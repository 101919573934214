// native
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// component
import { ListComponent } from './list/components/list/list.component';
import { NotFoundComponent } from './notfound/components/notfound.component';

const routes: Routes = [
  {
    path: 'page-not-found',
    component: NotFoundComponent,
    pathMatch: 'full'
  },
  {
    path: ':id',
    component: ListComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  }];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }