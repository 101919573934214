// native
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  userId;

  constructor() { }

  sendGoogleAnalyticsPageView() {
    window['gtag'] && window['gtag']('config', 'UA-134722866-4', {
      'app_id': 'public_listing',
      'app_name': 'Web',
      'user_id': this.userId || 'Anonymous'
    });
  }
}