// native
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Subscription } from 'rxjs';

// service
import { ListService } from '../../services/list.service';
import { AuthService } from '../../services/auth.service';

// constants
import { TYPE } from '../../../constants/constants';

// environment
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'rcl-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

  logoUrl: string;

  userHasCollectionSubscription: Subscription;

  constructor(
    private listService: ListService,
    public authService: AuthService
  ) {
    this.logoUrl = environment.baseUrls.readcube;
  }

  openPapers() {
    let entity = Object.assign({}, this.listService.entity);
    let url;

    switch (entity.type) {
      case TYPE.COLLECTION:
        url = `${environment.baseUrls.webapp}/library/${entity.collectionId}/all`;
        break;
      case TYPE.LIST:
        url = `${environment.baseUrls.webapp}/library/${entity.collectionId}/list/${entity.listId}`;
        break;
      case TYPE.SMARTLIST:
        url = `${environment.baseUrls.webapp}/library/${entity.collectionId}/smartlist/${entity.listId}`;

        url = url + `?query=${entity.query ? entity.query : ''}`;

        if (entity.sortField && entity.sortOrder)
          url = url + `&sort=${entity.sortField}&order=${entity.sortOrder}`;

        break;
    }

    window.open(url, '_blank');
  }
}